<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-12">
              <CRow class="align-items-center">
                <CHeaderNav>
                  <CHeaderNavItem class="px-3">
                    <button class="c-header-nav-btn" @click="openSidebarEvent('orderFilter')">
                      <CIcon v-if="!loading" size="lg" name="cil-filter" class="mr-2"/>
                    </button>
                  </CHeaderNavItem>
                </CHeaderNav>
                <div>
                  <CDropdown toggler-text="Sipariş" color="dark" class="m-1">
                    <div role="group">
                      <CDropdownItem :disabled="selectedOrders.length === 0" @click="orderDetail">Detay </CDropdownItem>
                      <CDropdownItem @click="preparingList">Preparing List</CDropdownItem>
                      <CDropdownItem :disabled="selectedOrders.length === 0" @click="checkList">Checklist
                      </CDropdownItem>
                      <CDropdownItem
                          :disabled="(selectedOrders.length === 0 || selectedOrders[0].customerId !== '')"
                          @click="openModalEvent('updateOrderAsCustomerConnection', 'confirm', selectedOrders[0], 'Onay',  selectedOrders[0].id+' numaralı siparişi kayıtlı olan müşteri ile birleştirmek istediğine emin misin?')">
                            Müşteri Bağla
                      </CDropdownItem>
                      <CDropdownItem
                        :disabled="(selectedOrders.length === 0 || (selectedOrders[0].orderStatus !== 'waiting_for_payment' && selectedOrders[0].orderStatus !== 'waiting_for_payment_gg'))"
                        @click="openModalEvent('updatePayment', 'orderUpdatePayment', selectedOrders[0], 'Ödeme Tutarı Gir: '+ selectedOrders[0].id)">
                          Ödeme Tutarı Gir
                      </CDropdownItem>
                    </div>
                  </CDropdown>
                </div>
                <div>
                  <CDropdown toggler-text="Fatura" color="warning" class="m-1" >
                    <div role="group">
                      <CDropdownItem
                          :disabled="(selectedOrders.length === 0 ||selectedOrders[0].orderStatus !== 'preparing')"
                          @click="prepareIrsaliye">Fatura Hazırla
                      </CDropdownItem>
                      <CDropdownItem disabled>Toplu Fatura Hazırla</CDropdownItem>
                      <CDropdownItem @click="printPackingSlipNew"
                                     :disabled="selectedOrders.length === 0 || (selectedOrders[0].orderStatus !== 'shipping' && selectedOrders[0].orderStatus !== 'tracking' && selectedOrders[0].orderStatus !== 'delivered')">
                        Fatura Yazdır
                      </CDropdownItem>
                      <CDropdownItem @click="openModalEvent('printPackingSlip', 'orderPrintPackingSlipForm', null, 'Toplu Sevk İrsaliyesi Yazdırma Seçim Ekranı')">Toplu Fatura Yazdır</CDropdownItem>
                      <CDropdownItem @click="openModalEvent('add', 'orderGIBForm', null, 'GIB e Bildirilen Siparişleri Kaydet')">
                        GIB'e Bildirilen Siparişleri Gir
                      </CDropdownItem>
                      <CDropdownItem
                          :disabled="(selectedOrders.length === 0 || !selectedOrders[0].invoicePrintedFlag)"
                          @click="openModalEvent('updateInvoice', 'orderUpdateInvoice', selectedOrders[0], 'Fatura No Giriş Ekranı - Sipariş No:'+ selectedOrders[0].id)">
                          Siparişe Fatura No Gir
                      </CDropdownItem>
                      <CDropdownItem @click="printReturnForm" :disabled="(selectedOrders.length === 0 || !selectedOrders[0].invoicePrintedFlag ||
                      selectedOrders[0].orderStatus === 'waiting_for_payment' || selectedOrders[0].orderStatus === 'canceled' || selectedOrders[0].countryName === 'Türkiye')">
                        İade Formu Bas
                      </CDropdownItem>
                      <CDropdownItem @click="openModalEvent('add', 'orderPrintReturnForm', null, 'Toplu İade Formu Bas')">
                        Toplu İade Formu Bas
                      </CDropdownItem>
                      <CDropdownItem @click="printExportForm" :disabled="(selectedOrders.length === 0 || !selectedOrders[0].invoicePrintedFlag ||
                      selectedOrders[0].orderStatus === 'waiting_for_payment' || selectedOrders[0].orderStatus === 'canceled' ||selectedOrders[0].countryName === 'Türkiye')">
                        İhracat Bilgi Formu Bas
                      </CDropdownItem>
                      <CDropdownItem @click="detailInvoice" :disabled="(selectedOrders.length === 0 || !selectedOrders[0].invoicePrintedFlag ||
                      selectedOrders[0].orderStatus === 'waiting_for_payment' || selectedOrders[0].orderStatus === 'canceled')">
                        Fatura Detay Görüntüle
                      </CDropdownItem>
                      <CDropdownItem @click="eArchiveInvoicePrint" :disabled="(selectedOrders.length === 0)">
                        E-Arşiv Fatura Bas
                      </CDropdownItem>
                      <CDropdownItem @click="iadeIhracatEArchiveInvoicePrint" :disabled="(selectedOrders.length === 0 || !selectedOrders[0].invoicePrintedFlag ||
                      selectedOrders[0].orderStatus === 'waiting_for_payment' || selectedOrders[0].orderStatus === 'canceled' ||
                      selectedOrders[0].countryName === 'Türkiye')">
                        İade,İhracat ve E-Arşiv Bas
                      </CDropdownItem>
                    </div>
                  </CDropdown>
                </div>
                <div>
                  <CDropdown
                      toggler-text="Hediye" color="dark"
                      class="m-1"
                  >
                    <div role="group">
                      <CDropdownItem @click="printGift" :disabled="selectedOrders.length === 0 || !selectedOrders[0].invoicePrintedFlag || !selectedOrders[0].giftFlag">
                        Kart Bas
                      </CDropdownItem>
                      <CDropdownItem @click="openModalEvent('giftCard', 'orderPrintGiftCard', null, 'Toplu Hediye Kartı Bas' )">
                        Otomatik Kart Bas
                      </CDropdownItem>
                      <CDropdownItem @click="printGift" :disabled="this.selectedOrders.length === 0 || !this.selectedOrders[0].giftFlag">
                        Kart Görüntüle
                      </CDropdownItem>
                      <CDropdownItem :disabled="(selectedOrders.length === 0 || !selectedOrders[0].giftFlag)"
                        @click="openModalEvent('update', 'orderGiftCardForm', selectedOrders[0],'Hediye Kartı Düzenle: '+selectedOrders[0].id)">
                        Kart Düzenle
                      </CDropdownItem>
                      <CDropdownItem :disabled="(selectedOrders.length === 0 || selectedOrders[0].giftFlag)"
                        @click="openModalEvent('add','orderGiftCardForm', selectedOrders[0],'Hediye Kartı Ekle: '+selectedOrders[0].id)">
                        Siparişe Kart Ekle
                      </CDropdownItem>
                    </div>
                  </CDropdown>
                </div>
                <div>
                  <CDropdown toggler-text="Kargo" color="dark" class="m-1">
                    <div role="group">
                      <CDropdownItem @click="openModalEvent('update', 'orderCargoInfoForm', selectedOrders[0], 'Kargo Bilgisi - Sipariş No: '+selectedOrders[0].id)"
                       :disabled="selectedOrders.length === 0 || !selectedOrders[0].invoicePrintedFlag">
                        Kargo Bilgileri Gir
                      </CDropdownItem>
                      <CDropdownItem :disabled="selectedOrders.length === 0"
                       @click="openModalEvent('update', 'orderCargoMailForm', selectedOrders[0], 'Sipariş No: '+selectedOrders[0].id+' - '+selectedOrders[0].sAddressPersonName)">
                        E-posta Değişikliği
                      </CDropdownItem>
                      <CDropdownItem :disabled="selectedOrders.length === 0"
                        @click="openModalEvent('update', 'orderCargoAddressForm', selectedOrders[0], 'Sipariş No: '+selectedOrders[0].id+' - '+selectedOrders[0].sAddressPersonName)">
                        Adres Değişikliği
                      </CDropdownItem>
                      <CDropdownItem :disabled="this.selectedOrders.length === 0"
                        @click="openModalEvent('update', 'orderCargoPhoneForm', selectedOrders[0], 'Sipariş No: '+selectedOrders[0].id+' - '+selectedOrders[0].sAddressPersonName)">
                          Telefon Değişikliği
                      </CDropdownItem>
                      <CDropdownItem @click="informShippingCompany(true)" :disabled="selectedOrders.length === 0 || !(selectedOrders[0].orderStatus === 'preparing'
                      || selectedOrders[0].orderStatus === 'shipping' || selectedOrders[0].orderStatus === 'tracking' || selectedOrders[0].orderStatus === 'delivered')">
                        Kargo Firmasını Bilgilendir
                      </CDropdownItem>
                      <CDropdownItem @click="printShippingAddress" :disabled="selectedOrders.length === 0 || !selectedOrders[0].invoicePrintedFlag">
                        Kargo Adresleri Bas
                      </CDropdownItem>
                      <CDropdownItem @click="openModalEvent('excel', 'orderETGBForm', {}, 'Toplu ETGB Form Yazdırma Ekranı')">Toplu ETGB Form Bas</CDropdownItem>
                    </div>
                  </CDropdown>
                </div>
                <div>
                  <CDropdown
                      toggler-text="E-Fatura" color="dark"
                      class="m-1"
                  >
                    <div role="group">
                      <CDropdownItem @click="openModalEvent('update', 'orderEInvoiceInfoForm',selectedOrders[0], 'Sipariş No: '+selectedOrders[0].id )"
                        :disabled="(selectedOrders.length === 0 || selectedOrders[0].invoiceAddressId === '' ||  selectedOrders[0].iAddressCompanyTaxOffice === '')">
                        Firma E-Fatura Bilgilerini Güncelle
                      </CDropdownItem>
                      <CDropdownItem @click="openModalEvent('update', 'orderETTNForm',selectedOrders[0], 'Sipariş No: '+selectedOrders[0].id )"
                        :disabled="(selectedOrders.length === 0 || (selectedOrders[0].orderStatus !== 'shipping' && selectedOrders[0].orderStatus !== 'tracking' && selectedOrders[0].orderStatus !== 'delivered'))">
                        ETTN Güncelle / Ekle
                      </CDropdownItem>
                    </div>
                  </CDropdown>
                </div>
                <div>
                  <CDropdown
                      toggler-text="Sonlandr." color="dark"
                      class="m-1"
                  >
                    <div role="group">
                      <CDropdownItem
                          @click="openModalEvent('delivered', 'confirm',selectedOrders[0], 'Sipariş No: '+selectedOrders[0].id, 
                          selectedOrders[0].id + ' numaralı siparişin; (' + selectedOrders[0].sAddressPersonName + ' ' + ' - ' + 
                          selectedOrders[0].city + ' / ' + selectedOrders[0].countryName + ') müşteriye teslim edildiğinden emin misin?')"
                          :disabled="selectedOrders.length === 0 || !(selectedOrders[0].orderStatus === 'shipping' || selectedOrders[0].orderStatus === 'tracking')">
                        Sipariş Teslim Edildi
                      </CDropdownItem>
                      <CDropdownItem
                          @click="openModalEvent('returned', 'confirm', selectedOrders[0],'Sipariş No: '+selectedOrders[0].id, 
                          selectedOrders[0].id + ' numaralı siparişin; ('+ selectedOrders[0].sAddressPersonName +' - ' + selectedOrders[0].city + 
                          ' / ' + selectedOrders[0].countryName +') tüm ürünleri ve siparişin ücreti İADE edilecektir, emin misin?')"
                          :disabled="selectedOrders.length === 0 || (selectedOrders[0].orderStatus !== 'delivered')">
                        Sipariş İade Edildi
                      </CDropdownItem>
                      <CDropdownItem
                          @click="openModalEvent('canceled', 'confirm', selectedOrders[0],'Sipariş No: '+selectedOrders[0].id,
                          selectedOrders[0].id + ' numaralı siparişin; (' + selectedOrders[0].sAddressPersonName + ' - ' + selectedOrders[0].city +
                          ' / ' + selectedOrders[0].countryName +') sipariş İPTAL edilecek ve ücret iade edilecektir, emin misin')"
                          :disabled="selectedOrders.length === 0 || !(selectedOrders[0].orderStatus  === 'waiting_for_payment' || selectedOrders[0].orderStatus  ==='preparing')">
                        Sipariş İptal Edildi
                      </CDropdownItem>
                      <CDropdownItem @click="openChangeOrder" :disabled="selectedOrders.length === 0 || (selectedOrders[0].orderStatus === 'canceled')">
                        Sipariş Değişimi
                      </CDropdownItem>
                      <CDropdownItem @click="orderChangeLink" :disabled="selectedOrders.length === 0 || (selectedOrders[0].orderStatus === 'canceled')">
                        Kullanıcı İçin Sipariş Değişim Linki Al
                      </CDropdownItem>
                      <CDropdownItem @click="orderReturnPdfLink" :disabled="this.selectedOrders.length === 0"
                      >Değişim/İade Formu PDF Linki Al
                      </CDropdownItem>
                      <CDropdownItem @click="setFilterParams({orderStatus: orderStatusTypes.filter(r => r.value == 'shipping' || r.value == 'tracking'), invoicePrintedFlag: true, checkedByUser: false})"
                      >Gün Sonu: Kontrol Edilmeyen Siparişler
                      </CDropdownItem>
                      <CDropdownItem
                          @click="setFilterParams({orderStatus: orderStatusTypes.filter(r => r.value == 'shipping'), invoicePrintedFlag: true, informShippingCompanyTime: false})"
                      >Gün Sonu: Kargo Bilgileri Gönderilmeyenler
                      </CDropdownItem>
                      <CDropdownItem
                          @click="openModalEvent('addBlackList', 'confirm', selectedOrders[0], 'Siparişi Black Liste Ekle', selectedOrders[0].id+' no lu siparişi black liste eklemek üzeresin. Emin misin?')"
                          :disabled="this.selectedOrders.length === 0">Blacklist'e Ekle
                      </CDropdownItem>
                    </div>
                  </CDropdown>
                </div>
                <div>
                  <CDropdown
                      toggler-text="Excel Akt." color="dark"
                      class="m-1"
                  >
                    <div role="group">
                      <CDropdownItem @click="getExcelByOrders">Filtrelenmiş Listeyi Excel'e Aktar</CDropdownItem>
                    </div>
                  </CDropdown>
                </div>
                <div>
                  <CButton :disabled="selectedOrders.length !== 1" block pressed color="dark" aria-pressed="true" @click="userNoteSearch">
                    Not
                  </CButton>

                </div>
              </CRow>

            </div>
          </div>
        </CCardHeader>

        <CCardBody>
          <CRow>
            <CCol sm="12">
              <CDataTable
                  :items="orderList"
                  :fields="fields"
                  :loading="loading"
                  :items-per-page="10"
                  pagination
                  clickable-rows
                  @row-clicked="rowClicked"
                  @row-double-clicked="openOrderDetail"
                  v-on:refresh="refreshDataTable"
                  sorter
                  columnFilter
              >
              <template #select-header="{item,index}">
                <th style="vertical-align: middle; overflow: hidden; font-size: 12px;"> {{selectedOrders !== null ? selectedOrders.length : 0}}_Adet</th>
              </template>
              <template #select-filter="{item,index}">
                <div style="    display: flex; justify-content: center;">
                  <CInputCheckbox
                    :checked="allSelect"
                    @update:checked="() => check(item, -1)"
                    custom
                  />
                </div>
               
              </template>
              <template #id-filter="{item, index}">
                  <CInput
                      col="12"
                      style="margin-bottom: 0;"
                      :value.sync="filterParams.id"
                      v-on:keyup.enter="setFilterParams({id: filterParams.id}, true)"
                    />
                </template>
                <template #createTime="{item}">
                  <td> {{ dateFormat(item.createTime) }}</td>
                </template>

                <template #customerEmailAddress-filter="{item, index}">
                    <CInput
                      col="12"
                      style="margin-bottom: 0;"
                      :value.sync="filterParams.customerEmailAddress"
                      v-on:keyup.enter="setFilterParams({customerEmailAddress: filterParams.customerEmailAddress}, true)"
                    />
                </template>

                <template #sAddressPersonName-filter="{item, index}">
                    <CInput
                      col="12"
                      style="margin-bottom: 0;"
                      :value.sync="filterParams.sAddressPersonName"
                      v-on:keyup.enter="setFilterParams({sAddressPersonName: filterParams.sAddressPersonName}, true)"
                    />
                </template>

                <template #customerPhoneNumber-filter="{item, index}">
                    <CInput
                      col="12"
                      style="margin-bottom: 0;"
                      :value.sync="filterParams.customerPhoneNumber"
                      v-on:keyup.enter="setFilterParams({customerPhoneNumber: filterParams.customerPhoneNumber}, true)"
                    />
                </template>

                <template #paymentTime="{item}">
                  <td> {{ dateFormat(item.paymentTime) }}</td>
                </template>

                <template #orderStatus-filter="{item, index}">
                    <Multiselect
                        placeholder=""
                        horizontal
                        select-label="Seçiniz"
                        selected-label="Seçildi"
                        deselect-label="Kaldır"
                        size="sm"
                        v-model="filterParams.orderStatus"
                        :options="orderStatusTypes"
                        :multiple="true"
                        label="label"
                        track-by="label"
                        @input="() => setFilterParams({orderStatus: filterParams.orderStatus }, true)"
                    />
                </template>
                <template #orderStatus="{item}">
                  <td> <CBadge :color="getColors(orderStatusTypes.findIndex(e => e.value == item.orderStatus))">{{ item.orderStatus }}</CBadge></td>
                </template>

                <template #select="{item,index}">
                  <td style="display: flex; justify-content: center;">
                    <CInputCheckbox
                        :checked="item._selected"
                        @update:checked="() => check(item,index)"
                        custom
                    />
                  </td>
                </template>

                <template #customerNote="{item}">
                  <td @mouseover="getTooltipMessage(item,'customerNote')">
                    <p  v-c-tooltip.mouseenter="{content: customerNoteMessage,  placement}"
                     style="width: 70px;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;">
                      {{ item.customerNote }}
                    </p>
                  </td>
                </template>

                <template #countAdminUserNotes="{item}">
                  <td>
                    <CIcon v-if="item.countAdminUserNotes>0" class="green"
                           name="cil-check-alt"/>
                    <CIcon v-else name="cil-x" class="red"/>
                  </td>
                </template>

                <template #secure3DFlag="{item}">
                  <td>
                    <CIcon v-if="item.secure3DFlag" class="green"
                           name="cil-check-alt"/>
                  </td>
                </template>

                <template #ettn="{item}">
                  <td @mouseover="getTooltipMessage(item, 'ettn')">
                    <CIcon v-if="item.ettn" class="green"
                           name="cil-check-alt"/>
                    <CIcon v-show="!item.ettn" v-c-tooltip.mouseenter="{content: ettnInfoMessage}" name="cil-x"
                           class="red"/>
                  </td>
                </template>

                <template #sAddressPersonName="{item}">
                  <td style="width: 100px">
                    {{ item.sAddressPersonName }}
                  </td>
                </template>

                <template #informShippingCompanyTime-filter="{item, index}">
                  <CSelect
                    col="12"
                    style="margin-bottom: 0; padding: 0;"
                    :options="comboDataList"
                    :value.sync="filterParams.informShippingCompanyTime"
                    @change="setFilterParams({informShippingCompanyTime: filterParams.informShippingCompanyTime}, true)"
                  />
                </template>

                <template #informShippingCompanyTime="{item}">
                  <td @mouseover="getTooltipMessage(item, 'informShippingCompanyTime')">
                    <CIcon
                        v-c-tooltip.mouseenter="{content: informShippingCompanyTimeMessage}"
                        v-if="item.informShippingCompanyTime" class="green"
                        name="cil-check-alt"/>
                    <CIcon v-else name="cil-x" class="red"/>
                  </td>
                </template>

                <template #city="{item}">
                  <td @mouseover="getTooltipMessage(item,'city')" v-c-tooltip.mouseenter="{content: cityMessage}">
                    {{ item.city }}
                  </td>
                </template>

                <template #shippingTrackingNumber-filter="{item, index}">
                    <CInput
                      col="12"
                      style="margin-bottom: 0;"
                      :value.sync="filterParams.shippingTrackingNumber"
                      v-on:keyup.enter="setFilterParams({shippingTrackingNumber: filterParams.shippingTrackingNumber}, true)"
                    />
                </template>

                <template #paymentType="{item}">
                  <td v-if="item.creditCardType !== '' || item.bankName !== ''"
                      v-c-tooltip.mouseenter.click="{content: paymentTypeTool(item)}">
                      {{ paymentTypes.find( e => e.value == item.paymentType).label }}
                  </td>
                  <td v-else>{{  paymentTypes.find( e => e.value == item.paymentType).label  }}</td>
                </template>

                <template #customerId="{item}">
                  <td>
                    <router-link :to="'/social/customers?customerId='+item.customerId" target="_blank" >{{ item.customerId }}</router-link>  
                  </td>
                </template>

                <template #giftFlag-filter="{item, index}">
                  <CSelect
                    col="12"
                    style="margin-bottom: 0; padding: 0;"
                    :options="comboDataList"
                    :value.sync="filterParams.giftFlag"
                    @change="setFilterParams({giftFlag: filterParams.giftFlag}, true)"
                  />
                </template>

                <template #giftFlag="{item}">
                  <td>
                    <CIcon v-if="item.giftPrintedFlag" style=" color: cornflowerblue" name="cil-gift"/>
                    <CIcon v-else-if="item.giftFlag" name="cil-gift"/>
                  </td>
                </template>

                <template #invoiceReadyPrintFlag="{item}">
                  <td @mouseover="getTooltipMessage(item,'invoiceReadyPrintFlag')"
                      v-bind:style=" item.invoiceAddressId !== '' ? 'background-color: #FFFCA3' : '' ">
                    <CIcon
                        v-c-tooltip.mouseenter="{content: invoiceReadyPrintFlagMessage}"
                        v-if="item.invoiceReadyPrintFlag" class="green"
                        name="cil-check-alt"/>
                    <CIcon v-show="!item.invoiceReadyPrintFlag" name="cil-x" class="red"/>
                  </td>
                </template>

                <template #shippingCompanyName-filter="{item, index}">
                    <CSelect
                      col="12"
                      style="margin-bottom: 0; padding: 0;"
                      :options="shippingCompanies"
                      :value.sync="filterParams.shippingCompany"
                      @change="setFilterParams({shippingCompany: shippingCompanies.filter(r => r.value == filterParams.shippingCompany) }, true)"
                    />
                </template>

                <template #shippingCompanyName="{item}">
                  <td @mouseover="getTooltipMessage(item,'shippingCompanyName')" v-c-tooltip.mouseenter="{content: shippingCompanyNameMessage}">
                    {{ item.shippingCompanyName }}
                  </td>
                </template>

                <template #invoicePrintedFlag-filter="{item, index}">
                  <CSelect
                    col="12"
                    style="margin-bottom: 0; padding: 0;"
                    :options="comboDataList"
                    :value.sync="filterParams.invoicePrintedFlag"
                    @change="setFilterParams({invoicePrintedFlag: filterParams.invoicePrintedFlag}, true)"
                  />
                </template>

                <template #invoicePrintedFlag="{item}">
                  <td @mouseover="getTooltipMessage(item, 'invoicePrintedFlag')"
                      v-bind:style=" item.invoiceAddressId!== '' ? 'background-color: #FFFCA3' : '' ">
                    <CIcon
                        v-c-tooltip.mouseenter="{content: invoicePrintedFlagMessage}"
                        v-if="item.invoicePrintedFlag" class="green"
                        name="cil-check-alt"/>
                    <CIcon v-show="!item.invoicePrintedFlag" name="cil-x" class="red"/>
                  </td>
                </template>

                <template #checkedByUser-filter="{item, index}">
                    <CSelect
                      col="12"
                      style="margin-bottom: 0; padding: 0;"
                      :options="comboDataList"
                      :value.sync="filterParams.checkedByUser"
                      @change="setFilterParams({checkedByUser: filterParams.checkedByUser}, true)"
                    />
                </template>

                <template #checkedByUser="{item, index}">
                  <td  @mouseover="getTooltipMessage(item, 'checkedByUser')">
                    <CIcon
                        v-c-tooltip.mouseenter="{content: checkedUserMessage}"
                        v-if="item.checkedByUser" class="green" name="cil-check-alt">
                    </CIcon>
                    <CIcon v-show="!item.checkedByUser" name="cil-x" class="red"/>
                  </td>
                </template>

                <template #gibFlag-filter="{item, index}">
                  <CSelect
                    col="12"
                    style="margin-bottom: 0; padding: 0;"
                    :options="comboDataList"
                    :value.sync="filterParams.gibFlag"
                    @change="setFilterParams({gibFlag: filterParams.gibFlag}, true)"
                  />
                </template>

                <template #gibFlag="{item}">
                  <td>
                    <CIcon v-if="item.gibFlag" class="green" name="cil-check-alt"/>
                    <CIcon v-else-if="!item.gibFlag && (item.gibFlag !== '')" name="cil-x" class="red"/>
                  </td>
                </template>

                <template #companyEinvoiceMember="{item}">
                  <td
                      v-bind:style=" item.invoiceAddressId!== '' && item.companyEinvoiceMember ? 'background-color: #FFFCA3' : '' ">
                    <CIcon v-if="item.companyEinvoiceMember" class="green" name="cil-check-alt"/>
                    <CIcon v-else-if="item.companyEinvoiceMember === ''"/>
                    <CIcon v-else-if="!item.companyEinvoiceMember" name="cil-x" class="red"/>
                  </td>
                </template>

              </CDataTable>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    <ConfirmModal
      v-if="form == 'confirm'" 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="operationEvent"
      :desc="modalDesc"
      :data="modalProps"
      :actionType="actionType"
      :loading="loading"
      :size="confirmModalSize"
      ref="confirm"
      :isFrame="isFrame"
      :noFooter="noFooter"
    />
    <ListModal
      v-else-if="actionType == 'list'" 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :module="module"
      :form="form"
      :data="modalProps"
      :additionalButtons = "additionalButtons"
      :additionalEvent = "additionalEvent"
    />
    <FormModal 
      v-else
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="operationEvent"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
      :size="modalSize"
      :noConfirm="noConfirm"
    />
    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterOrders"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
  </CRow>
</template>

<script>
import FormModal from '../components/formModal'
import ConfirmModal from '../components/confirmModal'
import FilterSidebar from '../components/filterSidebar'
import ListModal from '../components/listModal'
import moment from "moment";
import { prepareFilterParams } from "@/helpers/custom"
import Multiselect from 'vue-multiselect'

export default {
  name: 'OrderList',
  components: {
   FormModal, ConfirmModal, FilterSidebar, ListModal, Multiselect
  },
  data() {
    return {
      allSelect: false,
      isFrame: false,
      noConfirm: false,
      confirmModalSize: 'sm',
      modalSize: 'lg',
      colors: [ 'success',  'info', 'primary',  'warning', 'danger', 'dark', 'secondary'],
      formControls: ['orderUpdatePayment', 'orderCargoInfoForm', 'orderGiftCardForm', 'orderCargoMailForm', 'orderCargoAddressForm'],
      modalTitle: '',
      actionType: '',
      module: 'orders',
      form: 'orderUpdatePayment',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      openSidebar: false,
      filterForm: 'orderFilter',
      filterParams: {},
      lastItem: {},
      noFooter: false,
      additionalButtons: [],
      activePage: 1,
      placement: 'bottom',
      customerNoteMessage: '',
      checkedUserMessage: '',
      ettnInfoMessage: '',
      informShippingCompanyTimeMessage: '',
      cityMessage: '',
      invoiceReadyPrintFlagMessage: '',
      shippingCompanyNameMessage: '',
      invoicePrintedFlagMessage: '',
      ids: [],
      fields: [
        {
          key: 'select',
          label: '',
          _style: 'min-width:1%',
          sorter: false,
          filter: false
        },
        {key: 'id', label: 'Sip.No', _style: 'font-size:12px', filter: true},
        {key: 'createTime', label: 'Sipariş_Zamanı', _style: 'font-size:12px', filter: false},
        {key: 'customerEmailAddress', label: 'Email', _style: 'font-size:12px',  filter: true},
        {key: 'customerId', label: 'Müşteri_Id', _style: 'font-size:12px', filter: false}, 
        {key: 'sAddressPersonName', label: 'Adı_Soyadı', _style: 'font-size:12px', filter: true},
        {key: 'customerPhoneNumber', label: 'Telefon', _style: 'font-size:12px', filter: true},
        {key: 'customerNote', label: 'Not', _style: 'font-size:12px', filter: false},
        {key: 'orderStatus', label: 'Durumu', _style: 'font-size:12px', filter: true},
        {key: 'promocode', label: 'Promosyon', _style: 'font-size:12px', filter: false},
        {key: 'cartPriceTotal', label: 'Tutar', _style: 'font-size:12px', filter: false},
        {key: 'invoiceReadyPrintFlag', label: 'Fatura_Hazırlama', _style: 'font-size:12px', filter: false},
        {key: 'invoicePrintedFlag', label: 'Fatura_Yazdırma', _style: 'font-size:12px', filter: true},
        {key: 'checkedByUser', label: 'Kontrol', _style: 'font-size:12px', filter: true},
        {key: 'companyEinvoiceMember', label: 'Firma_E.Fatura_Tabi', _style: 'font-size:12px', filter: false},
        {key: 'giftFlag', label: 'Hediye', _style: 'font-size:12px', filter: true},
        {key: 'invoiceNumber', label: 'Fatura_No', _style: 'font-size:12px', filter: false},
        {key: 'gibFlag', label: 'GIB', _style: 'font-size:12px', filter: true},
        {key: 'ettn', label: 'ETTN', _style: 'font-size:12px', filter: false},
        {key: 'shippingCompanyName', label: 'Kargo', _style: 'font-size:12px', filter: true},
        {key: 'informShippingCompanyTime', label: 'Bilgilendirme', _style: 'font-size:12px', filter: true},
        {key: 'city', label: 'Şehir', _style: 'font-size:12px', filter: false},
        {key: 'countryName', label: 'Ülke', _style: 'font-size:12px', filter: false},
        {key: 'shippingTrackingNumber', label: 'Takip_No', _style: 'font-size:12px', filter: true},
        {key: 'shippingPrice', label: 'Kargo_Ücreti', _style: 'font-size:12px', filter: false},
        {key: 'cartItemsTotalCount', label: 'Adet', _style: 'font-size:12px', filter: false},
        {key: 'paymentType', label: 'Ödeme_Şekli', _style: 'font-size:12px', filter: false},
        {key: 'secure3DFlag', label: '3DSecure', _style: 'font-size:12px', filter: false},
        {key: 'payerID', label: 'Ödeme_Id', _style: 'font-size:12px', filter: false},
        {key: 'installmentCount', label: 'Taksit', _style: 'font-size:12px', filter: false},
        {key: 'paymentTotal', label: 'Ödeme_Toplamı', _style: 'font-size:12px', filter: false},
        {key: 'paymentTime', label: 'Ödeme_Zamanı', _style: 'font-size:12px', filter: false},
        {key: 'refundAmount', label: 'İade_Toplamı', _style: 'font-size:12px', filter: false},
        {key: 'countAdminUserNotes', label: 'Op.Notu', _style: 'font-size:12px', filter: false},

      ],
      selectedOrders: [],
      selectedOrder: {},
      filters: [
        {
          type: 'numeric',
          dataIndex: 'id',
          field: 'id',
          comparison: 'eq'
        }, {
          type: 'string',
          dataIndex: 'customerEmailAddress',
          field: 'customerEmailAddress',
        }, {
          type: 'string',
          dataIndex: 'sAddressPersonName',
          field: 'sAddressPersonName',
        }, {
          type: 'string',
          dataIndex: 'shippingTrackingNumber',
          field: 'shippingTrackingNumber'
        }, {
          type: 'string',
          dataIndex: 'customerPhoneNumber',
          field: 'customerPhoneNumber',
        }, {
          type: 'string',
          dataIndex: 'city',
          field: 'city',
        }, {
          type: 'string',
          dataIndex: 'countryName',
          field: 'countryName',
        }, {
          type: 'listString',
          dataIndex: 'orderStatus',
          field: 'orderStatus',
        }, {
          type: 'boolean',
          dataIndex: 'invoiceReadyPrintFlag',
          field: 'invoiceReadyPrintFlag'
        }, {
          type: 'boolean',
          dataIndex: 'invoicePrintedFlag',
          field: 'invoicePrintedFlag'
        }, {
          type: 'boolean',
          dataIndex: 'checkedByUser',
          field: 'checkedByUser'
        }, {
          type: 'boolean',
          dataIndex: 'companyEinvoiceMember',
          field: 'companyEinvoiceMember'
        }, {
          type: 'boolean',
          dataIndex: 'informShippingCompanyTime',
          field: 'informShippingCompanyTime'
        }, {
          type: 'boolean',
          dataIndex: 'giftFlag',
          field: 'giftFlag',
        }, {
          type: 'boolean',
          dataIndex: 'giftPrintedFlag',
          field: 'giftPrintedFlag',
        }, {
          type: 'integer',
          dataIndex: 'invoiceNumber',
          field: 'invoiceNumber',
          comparison: 'eq'
        }, {
          type: 'boolean',
          dataIndex: 'gibFlag',
          field: 'gibFlag'
        }, {
          type: 'boolean',
          dataIndex: 'ettn',
          field: 'ettn'
        }, {
          type: 'string',
          dataIndex: 'promocode',
          field: 'promocode',
        }, {
          dataIndex: 'paymentType',
          type: 'integer',
          field: 'paymentType',
        }, {
          type: 'boolean',
          dataIndex: 'secure3DFlag',
          field: 'secure3DFlag'
        }, {
          type: 'string',
          dataIndex: 'payerID',
          field: 'payerID'
        }, {
          type: 'numeric',
          dataIndex: 'paymentLogId',
          field: 'paymentLogId',
          comparison: 'eq'
        }, {
          type: 'numeric',
          dataIndex: 'paymentTotal',
          field: 'paymentTotal',
          comparison: 'eq'
        }, {
          type: 'date',
          dataIndex: 'paymentTime',
          field: 'paymentTime',
          comparison: 'eq'
        }, {
          type: 'listString',
          dataIndex: 'shippingCompany',
          field: 'shippingCompanyId'
        },
        {
          type: 'date',
          dataIndex: 'startTime',
          field: 'createTime',
          comparison: 'gt'
        },
        {
          type: 'date',
          dataIndex: 'endTime',
          field: 'createTime',
          comparison: 'lt'
        }
      ],
      paymentTypes: [
        {value: '1', label: 'PayPalEC'},
        {value: '2', label: 'EFT'}, 
        {value: '3', label: 'CreditCard'},
        {value: '4', label: 'FreePromo'},
        {value: '5', label: 'DebitCard'},
        {value: '6', label: 'Cash'},
        {value: '7', label: 'GiroGate'},
        {value: '8', label: 'GarantiPay'},
        {value: '10', label: 'IyzicoPay'}
      ],
      comboDataList: [
        {label: "Hepsi", value: ""},
        {label: "Evet", value: "true"},
        {label: "Hayır", value: "false"}
      ],
      momentFormat: moment,
      shippingCompany: null,
      disableStates: [200, 37, 12]
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
  },
  computed: {
    loading: function(){
      return this.$store.getters.orderLoading
    },
    orderList: function(){
      return this.$store.getters.orderList
    },
    orderStatusTypes: function() {
      let data = [{label: "Seçiniz", value: undefined}]
      this.$store.getters.orderStatusTypes.map(r => data.push({value:r.type, label: r.friendlyType}))
      return data
    },
    shippingCompanies: function(){
      let data = [{label: "Seçiniz", value: undefined}]
      this.$store.getters.shippingCompanyList.map(r => data.push({value:r.id, label: r.name}))
      return data
    },
    reloadParams() {
      return [
        this.activePage
      ]
    }
  },
  methods: {

    checkData(data, keys, val){
      let toast = {}
      let status = true;

      if(keys.includes('length')){
        if(data.length > val){
          toast.msg = 'Lütfen, En fazla '+val+' satır seçimi yapın!'
          toast.header = 'Uyarı'
          toast.color = 'warning'
          status = false
        }
      } 

      if(status && keys.includes('equals')){
        if(data[0] !== data[1]){
          toast.msg = 'Ödeme toplamı sipariş toplamıyla aynı olmalıdır!'
          toast.header = 'Dikkat'
          toast.color = 'danger'
          status = false
        }
      }

      if(status && keys.includes('orderStatus')){
        if(val.includes(data.orderStatus)){
          toast.msg = 'İptal ya da iade olmuş sipariş(' + data.id + ') seçili, bu siparişin seçili olma durumunu kaldırın!'
          toast.header = 'Dikkat'
          toast.color = 'danger'
          status = false
        }
      }

      if(status && keys.includes('status')){
        for(let i = 0; i < val.length; i++){
          if(data[val[i]] == '' || data[val[i]] == null || data[val[i]] == false ){
            if(val[i] == 'ettn' || val[i] == 'gibFlag'){
              toast.msg = 'Bu siparişin ettn numarası girilmemiş ya da gib e bildirilmemiş, kontrol edin.'
              status = false
            }
            else if(val[i] == 'shippingTrackingNumber'){
              toast.msg = 'Bu faturaya ait Konşimento Numarası yoktur!'
              status = false
            }
          }else{
            if(val[i] == 'invoicePrintedFlag'){
              toast.msg = '(*) Bu fatura daha önce basılmıştır.'
              status = false
            }
            else if(val[i] == 'giftPrintedFlag'){
              toast.msg = '(*) Bu hediye kartı daha önce basılmıştır.'
              status = false
            }
          }
          if(status == false)
            break
        }
      }

      Object.keys(toast).length > 0 && vm.$store.commit('showToast', toast)
      return status
    },

    setFilterParams: function(params, combine){
      if(combine){
        this.filterParams = {...this.filterParams, ...params};
      }else
        this.filterParams = params
      this.filterOrders(this.filterParams)
    },

    async openModalEvent(actionType, form, data, title, desc){
      this.confirmModalSize = 'sm'
      this.modalSize = 'lg'
      this.isFrame = false
      this.additionalButtons = []
      this.noFooter = false
      this.noConfirm = false
      if(actionType == 'print'){
        this.confirmModalSize = 'xl'
        this.isFrame = true
      }else if(this.formControls.includes(form)){
        if(!this.checkData(this.selectedOrders, ['length'], 1))
          return;
        if(form == 'orderCargoInfoForm'){
          data.shippingEtgbDate = moment(this.selectedOrders[0].shippingEtgbDate).format('YYYY-MM-DD');
          this.$store.dispatch('order_regionShippingCompanies', {orderId: this.selectedOrders[0].id })
        }else if(form == 'orderCargoAddressForm'){
          if(this.disableStates.includes(data.countryId)){
            await this.$store.dispatch('allStates_list', {countryId: data.countryId})
          }else{
            await this.$store.dispatch('allCities_list', {countryId: data.countryId})
          }
          await this.$store.dispatch('allDistrict_list', {cityName: data.city})
        }
      }else if(form == 'orderPrepareInvoiceList'){
        this.additionalButtons = [  {name: 'Fatura Hazırla', color: 'success', action: 'invoivePrepare'} ]
      }else if(form == 'orderPreparePackingSlipList'){
        this.additionalButtons = [  {name: 'Fatura Yazdır', color: 'success', action: 'printInvoice'} ]
      }else if( form == 'orderETGBForm'){
        data.endDate = moment.utc().local().format('YYYY-MM-DD HH:mm')
        data.startDate = moment.utc().local().subtract(1,"days").format('YYYY-MM-DD HH:mm');
      }else if( form == 'orderGIBForm'){
        this.modalSize = 'sm'
      }else if( form == 'orderPrintPackingSlipForm'){
        this.noConfirm = true
      }
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },

    async additionalEvent(item, action){
      if(action == 'invoivePrepare'){
        await this.$store.dispatch('order_prepareEIrsaliye', {"orderIds": this.selectedOrders[0].id})
        if(this.$store.getters.orderStatus.success){
          this.openModalEvent()
          this.filterOrders(this.filterParams)
        }
      }else if( action == 'printInvoice'){
        this.openModalEvent()
        await this.eInvoicePrint(this.selectedOrders[0].id, this.selectedOrders[0].customerEmailAddress, true, this.selectedOrders[0].invoiceAddressId !== null);
      }
    },

    openSidebarEvent( form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },

    dateFormat(date){
      return moment(date).format('DD.MM.YYYY HH:mm:ss')
    },

    getColors(index){
      return this.colors[index  % this.colors.length]
    },

    async filterOrders(params) {
      this.allSelect = false;
      this.selectedOrders = []
      this.selectedOrder = {}

      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)

      await this.$store.dispatch('order_list', formData)
      
      if(this.openSidebar){
        await this.openSidebarEvent()
      }
    },

    async operationEvent(item, action) {
      let params;
      if(action !== 'print')
         params = { orderId: item ? item.id : this.selectedOrders[0] ? this.selectedOrders[0].id : 0}
      if(action == 'print'){
        this.$refs.confirm.$refs.desc.contentWindow.printPage();
        if(item){
          await this[item]()
        }
        if(this.openModal){
          await this.filterOrders(this.filterParams)
          this.openModalEvent()
          this.selectedOrders = []
        }
        return
      }else if(this.form == 'confirm'){
        if(action == 'updateOrderAsCustomerConnection'){
          params.emailAddress = item.customerEmailAddress
          await this.$store.dispatch('order_updateOrderAsCustomerConnection', params)
        }else if(action == 'addBlackList'){
          let formData = new FormData()
          formData.append('orderId', params.orderId)
          await this.$store.dispatch('order_addBlackList', formData)
        }else {
          await this.$store.dispatch('order_'+action, params)
        }
      }else if(this.form == 'orderUpdatePayment'){
        
        if(!this.checkData(item, ['equals'], [item.paymentAmount, item.cartPriceTotal]))
          return
        else{
          params.amount = item.paymentAmount
          await this.$store.dispatch('order_updatePaymentAmount', params)
        }
      }else if(this.form == 'orderPrintPackingSlipForm'){
        params.maxLimit = 20;
        params.giftCard = item.giftCard;
        params.nots = item.nots;
        params.shippingCompany = item.shippingCompany;
        this.shippingCompany = item.shippingCompany
        await this.$store.dispatch('order_readyToPrintEInvoice', params)
        if(this.$store.getters.orderStatus.success){
          params = {
            "readyToPrintOrderIds": this.$store.getters.orderStatus.ids,
            "isPackingSlip": true
          };
          await this.$store.dispatch('order_eInvoicePrintAuto', params)
          await this.openModalEvent()
          await this.openModalEvent('print', 'confirm', 'confirmEInvoicePrintAuto', 'Otomatik Fatura Bas : '+this.$store.getters.orderStatus.ids+' faturaları yazdırmak ister misin ? ')
          this.$refs.confirm.$refs.desc.contentDocument.write(this.$store.getters.orderJSP)
          return
        }
      }else if(this.form == 'orderGIBForm'){
        params.orderIds = item.orderIdsForGibs
        await this.$store.dispatch('order_saveOrdersGib', params)
        if(this.$store.getters.orderStatus.success){
          const toast = {
            msg: 'Girdiğin ' + this.$store.getters.orderStatus.ids + ' adet siparişden, ' + this.$store.getters.orderStatus.count + ' adet sipariş kaydedildi.',
            header: 'Bilgi',
            color: 'info'
          }
          await vm.$store.commit('showToast', toast)
        }
      }else if(this.form == 'orderUpdateInvoice'){
        params.invoiceNo = item.invoiceNo
        await this.$store.dispatch('order_updateInvoice', params)
      }else if(this.form == 'orderPrintReturnForm'){
        await this.$store.dispatch('order_getReturnFormIds', {"shippingCompany": item.shippingCompany})
        await this.$store.dispatch('order_printReturnForm',  {"orderIds": this.$store.getters.orderStatus.orderIds})
        await this.openModalEvent()
        await this.openModalEvent('print', 'confirm', null, 'Toplu İade Formu Bas: '+this.$store.getters.orderStatus.orderIds+' formu yazdırmak ister misin?')
        this.$refs.confirm.$refs.desc.contentDocument.write(this.$store.getters.orderJSP)
        return
      }else if(this.form == 'orderPrintGiftCard'){
        await this.$store.dispatch('order_giftPrintData',{ "maxLimit": 10, "shippingCompany": item.shippingCompany})
        if(this.$store.getters.orderStatus.success){
          await this.$store.dispatch('order_giftPrintAuto', {"readyToPrintGiftIds": this.$store.getters.orderStatus.ids})
          await this.openModalEvent()
          await this.openModalEvent('print', 'confirm', 'confirmPrintAllGift', 'Otomatik Hediye Kartı Bas: '+this.$store.getters.orderStatus.ids+' yazdırmak ister misin?')
          this.$refs.confirm.$refs.desc.contentDocument.write(this.$store.getters.orderJSP)
          return
        }
      }else if(this.form == 'orderGiftCardForm'){
        params.actionType = action
        params.emailAddress = item.customerEmailAddress
        params.senderName = item.giftSenderName
        params.senderNote = item.giftSenderNote

        await this.$store.dispatch('order_giftCard', params)
      }else if(this.form == 'orderCargoInfoForm'){
        params.shippingCompany = item.shippingCompanyId
        params.receiptNumber = item.shippingReceiptNumber
        params.trackingNumber = item.shippingTrackingNumber
        params.price = item.shippingPrice
        params.extraPrice = item.shippingExtraPrice
        params.etgbNo = item.shippingEtgbNo

        if(moment(item.shippingEtgbDate).isValid())
          params.etgbDate = moment(item.shippingEtgbDate).format("MM/DD/YYYY")

        await this.$store.dispatch('order_cargoInfo', params)
        
      }else if(this.form == 'orderCargoMailForm'){
        params.id = item.id
        params.customerEmailAddress = item.customerEmailAddress

        await this.$store.dispatch('order_changeEmail', params)
      }else if(this.form == 'orderCargoAddressForm'){
        var formData = new FormData();
        formData.append("id",item.id);
        formData.append("stateId",item.stateId);
        formData.append("countryId",item.countryId);
        formData.append("addressLine",item.addressLine);
        formData.append("city",item.city);
        formData.append("district",item.district);
        formData.append("postalCode",item.postalCode);
        formData.append("companyName",item.companyName);
        formData.append("personName",item.sAddressPersonName);
        formData.append("companyTaxInfo",item.companyTaxInfo);
        await this.$store.dispatch('order_changeAddress', formData)
      }else if(this.form == 'orderCargoPhoneForm'){
        params.id = item.id
        params.customerPhoneNumber = item.customerPhoneNumber
        await this.$store.dispatch('order_changePhone', params)
      }else if(this.form == 'orderETGBForm'){
        params = {
          shippingCompany: item.shippingCompany, 
          startDate: moment(item.startDate).format('DD.MM.YYYY HH:mm:ss'),
          endDate:  moment(item.endDate).format('DD.MM.YYYY HH:mm:ss'),
          responseType: 'blob'
        };

        await this.$store.dispatch('order_etgbFormExcel', params)
        if(this.$store.getters.orderStatus){
          const url = window.URL.createObjectURL(new Blob([this.$store.getters.orderStatus]));
          const link = document.createElement('a');
          link.href = url;
          let fileName = (item.shippingCompany == 4 ? 'DHL_' : 'UPS_') + 'TopluETGB_' +  moment(new Date()).format('DD/MM/YYYY') + '.xls'
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        }
      }else if( this.form == 'orderEInvoiceInfoForm'){
        params.companyName = item.companyName
        params.companyTaxNumber = item.companyTaxNumber
        params.companyEinvoiceMember = item.companyEinvoiceMember

        await this.$store.dispatch('order_companyEInvoice', params)
      } else if( this.form == 'orderETTNForm'){
        params.ettnNo = item.ettn

        await this.$store.dispatch('order_ettn', params)
      }
      if(this.$store.getters.orderStatus.success){
        this.filterOrders(this.filterParams)
        this.openModalEvent()
        this.selectedOrders = []
      }
    },

    async confirmEInvoicePrintAuto(){

      let params = {
        orderIds: this.$store.getters.orderStatus.ids,
        invoiceIds: this.$refs.confirm.$refs.desc.contentWindow.getInvoiceIds()
      }

      this.ids = this.$store.getters.orderStatus.ids
      this.selectedOrders = [];

      await this.$store.dispatch('order_updateOrderAndInvoiceStatus', params )

      if(this.$store.getters.orderStatus.success){
        // DHL veya UPS ise bildir
        if ([4,6].includes(this.shippingCompany)) {
          this.informShippingCompany(undefined)
        } 

        this.openModalEvent()
        await this.filterOrders(this.filterParams);
      }
    },

    getTooltipMessage(item,type) {
      if(type === 'customerNote') {
        this.customerNoteMessage = '';
        this.customerNoteMessage = item.customerNote;
      } else if(type === 'checkedByUser') {
        this.checkedUserMessage = '';
        if (item.checkedByUser !== '') {
          this.checkedUserMessage = 'Kontrol eden: ' + item.checkedUserName + ' ' + this.momentFormat(item.checkedTime).format('DD.MM.YYYY HH:mm')
        }
      }else if(type === 'ettn') {
        this.ettnInfoMessage = '';
        if (!item.ettn) {
          this.ettnInfoMessage = 'Bu siparişe ETTN girilmemiş';
        }
      }else if(type === 'informShippingCompanyTime') {
        this.informShippingCompanyTimeMessage = '';
        if (item.informShippingCompanyTime!== null) {
          this.informShippingCompanyTimeMessage = 'Kargo Firması Bilgilendirme Tarihi: ' +  this.momentFormat(item.informShippingCompanyTime).format('DD.MM.YYYY HH:mm');
        }
      }else if(type === 'city') {
        this.cityMessage = '';
        this.cityMessage = 'Adres Satırı: ' + item.addressLine;
      } else if(type === 'invoiceReadyPrintFlag') {
        this.invoiceReadyPrintFlagMessage = '';
        this.invoiceReadyPrintFlagMessage = 'Fatura hazırlanma tarihi: ' +  this.momentFormat(item.invoiceReadyPrintTime).format('DD.MM.YYYY HH:mm');
      } else if(type === 'shippingCompanyName') {
        this.shippingCompanyNameMessage ='';
        this.shippingCompanyNameMessage ='Tahmini teslim tarihi: ' +  this.momentFormat(item.estimatedDeliveryTime).format('DD.MM.YYYY HH:mm');
      } else if(type === 'invoicePrintedFlag') {
        this.invoicePrintedFlagMessage ='';
        this.invoicePrintedFlagMessage ='Fatura kesim tarihi: ' +  this.momentFormat(item.invoicePrintTime).format('DD.MM.YYYY HH:mm');
      }
    },
    async rowClicked(item, index, column, e) {
      if (column === "paymentType") {  
        var toolHtml = '<h4>' + item.creditCardType + (item.secure3DFlag?', (3DSecure)':'') +'</h4>'
          +'<div><i>'+ item.bankName+'</i></div>' 
          +'<br/><h4>' + item.transactionID != '' && 'Transaction ID' + '</h4>'
          +'<div><i>'+ item.transactionID + '</i></div>'
          +'<br/><h4>' + (item.posName != '' && 'Pos Name') + '</h4>'
          +'<div><i>'+ ( item.posName ) + '</i></div>'
          +'<br/><h4>' + (item.paymentUniqueToken != '' && 'Unique Token') + '</h4>'
          +'<div><i>'+ (item.paymentUniqueToken) + '</i></div>';
        this.openModalEvent('info', 'confirm', null, "Bilgilendirme - Order Id: " + item.id, toolHtml)
        this.noFooter = true
      }else if(column === "refundAmount" && item.refundAmount>0) {
        await this.$store.dispatch('order_refundLogs', {paymentLogId: item.paymentLogId})
        this.openModalEvent('list', 'orderRefundLogList', item, item.id + 'nolu siparişin iade bilgileri')
      }else if(column === "customerNote") {
        if(item.customerNote !== "") {
          await this.copyStringToClipboard( window.getSelection().toString() !== '' ?  window.getSelection().toString() : item.customerNote)
          const toast = {
            msg: 'Müşteri notu kopyalandı',
            header: 'Başarılı',
            color: 'success'
          }
          vm.$store.commit('showToast', toast)
        }
      }

      if (column !== "select") {
        this.selectedOrders = [];
        this.selectedOrder = {};
        for(var i = 0; i <this.orderList.length; i++){
          this.$set(this.orderList[i], '_selected', false);
          this.$set(this.orderList[i], '_classes', '');
        }
        const val = Boolean(this.orderList.find(e => e.id == item.id)._selected);
        this.$set(this.orderList.find(e => e.id == item.id), '_selected', !val);
        this.$set(this.orderList.find(e => e.id == item.id), '_classes', !val ? 'selected' : '');
        this.orderList.map(u => {
          if (u._selected) {
            this.selectedOrders.push(u);
          }
        });
        this.selectedOrder = this.selectedOrders[0];
        this.allSelect = false
      } else {
        this.check(item,index);
      }
    },
    async openOrderDetail(item, index) {
      await this.orderDetail();
      await this.check(item,index)
    },
    async refreshDataTable() {
      await this.filterOrders(this.filterParams);
    },
    async check(item, index) {
      let tmpSelected = []
      this.selectedOrder = {};
      if(index > -1){
        const val = Boolean(this.orderList[index]._selected)
        this.$set(this.orderList[index], '_selected', !val)
        this.$set(this.orderList[index], '_classes', !val ? 'selected' : '');
        await this.orderList.map( u => {
          if (u._selected) {
           tmpSelected.push(u)
          }
        });
        this.selectedOrder =tmpSelected[0];
      }else{
        if(this.allSelect){
          await this.orderList.map((u, i) => {
            this.$set(this.orderList[i], '_selected', false)
            this.$set(this.orderList[i], '_classes', '');

          })
        }else{
          await this.orderList.map((u, i) => {
            this.$set(this.orderList[i], '_selected', true)
            this.$set(this.orderList[i], '_classes', 'selected' );
            tmpSelected.push(u)
          })
        }
      }
      this.selectedOrders = [...tmpSelected]
      this.allSelect = this.orderList.every(o => o._selected === true)
    },
  
    async preparingList() {
      await this.$store.dispatch('order_PreparingList')
      await this.openModalEvent('print', 'confirm', null, 'Sipariş Preparing List yazdırmak ister misin?')
      this.$refs.confirm.$refs.desc.contentDocument.write(this.$store.getters.orderJSP)
    },
    async printShippingAddress() {
      if(!this.checkData(this.selectedOrders, ['length'], 10)) return
      let selected = this.selectedOrders.map(so =>  so.id +','+so.customerEmailAddress)
      await this.$store.dispatch('order_shippingAddress', {paramStr: selected.join('|')})
      await this.openModalEvent('print', 'confirm', null, 'Kargo gönderi adreslerini yazdırmak ister misin?')
      this.$refs.confirm.$refs.desc.contentDocument.write(this.$store.getters.orderJSP)
    },
    async printExportForm(){
      if(!this.checkData(this.selectedOrders, ['length'], 1)) return
      if(!this.checkData(this.selectedOrders[0], ['status'], ['shippingTrackingNumber'])) return

      const params = {
        "orderId": this.selectedOrders[0].id
      };
      await this.$store.dispatch('order_printEtgbForm', params);
      await this.openModalEvent('print', 'confirm', null, 'İhracat Bilgi Formu Bas: '+ this.selectedOrders[0].id +' formu yazırmak ister misin ? ')
      this.$refs.confirm.$refs.desc.contentDocument.write(this.$store.getters.orderJSP) 
    },
   
    async printGift() {
      if(!this.checkData(this.selectedOrders, ['length'], 1)) return
      !this.checkData(this.selectedOrders[0], ['status'], ['giftPrintedFlag'])

      const params = {
        "orderId": this.selectedOrders[0].id,
        "emailAddress": this.selectedOrders[0].customerEmailAddress
      };

      await this.$store.dispatch('order_giftPrinted', params)
      await this.openModalEvent('print', 'confirm', 'confirmPrintGiftCard', 'Hediye Kartını yazırmak ister misin ? ')
      this.$refs.confirm.$refs.desc.contentDocument.write(this.$store.getters.orderJSP) 
    },
    async confirmPrintAllGift(){
      let params = {
        "orderIds": this.$store.getters.orderStatus.ids
      }
      await this.$store.dispatch('order_giftPrintUpdate', params)
      if(this.$store.getters.orderStatus.success){
        this.openModalEvent()
        this.filterOrders(this.filterParams);
      }
    },
    async confirmPrintGiftCard(){
      let params = {
        "orderIds": this.selectedOrders[0].id
      }
      await this.$store.dispatch('order_giftPrintUpdate', params)
      if(this.$store.getters.orderStatus.success){
        this.openModalEvent()
        this.filterOrders(this.filterParams);
      }

    },
    async eArchiveInvoicePrint() {
      await this.$store.dispatch('order_getInvoicePdf',{"id": this.selectedOrders[0].id, "order": true });
      if(this.$store.getters.orderStatus.success){
        //await this.openModalEvent('print', 'confirm', null, 'E-Arşiv Fatura: '+ this.selectedOrders[0].id )
        //this.$refs.confirm.$refs.desc.src= this.$store.getters.orderStatus.pdf
        //this.noFooter = true
        window.open(this.$store.getters.orderStatus.pdf);
      }else {
        const toast = {
          msg: 'Ettn numarası girilmemiş olabilir, kontrol edin.',
          header: 'Uyarı',
          color: 'warning'
        }
        vm.$store.commit('showToast', toast)
      }
    },
    async orderChangeLink() {
      await this.$store.dispatch('encryptemail',{ "orderId": this.selectedOrder.id, "email": this.selectedOrder.customerEmailAddress})
      if(this.$store.getters.encrytedEmail.success){
        let url = process.env.VUE_APP_SERVERINFO_WEBDOMAIN + "/order/change?id=" + this.selectedOrder.id + "&email=" + this.$store.getters.encrytedEmail.encrytedEmail;
        await this.copyStringToClipboard(url)
        const toast = {
          msg: 'Sipariş Değişim linki kopyalanmıştır.',
          header: 'Kopyalandı',
          color: 'success'
        }
        vm.$store.commit('showToast', toast)
      }
    },
    async orderReturnPdfLink() {
        let getPdfLink = "";
        if (this.selectedOrder.countryId === 192) {
          getPdfLink = process.env.VUE_APP_SERVERINFO_WEBDOMAIN + "/static/returnform/kaft_return_local_" + this.selectedOrder.langCode + ".pdf";
        } else {
          getPdfLink = process.env.VUE_APP_SERVERINFO_WEBDOMAIN + "/static/returnform/kaft_return_" + this.selectedOrder.warehouseAddressCountryCode.toLowerCase() + "_" + this.selectedOrder.langCode + ".pdf";
        }
        await this.copyStringToClipboard(getPdfLink);
        const toast = {
          msg: 'Sipariş Değişim / İade formu linki kopyalanmıştır.',
          header: 'Kopyalandı',
          color: 'success'
        }
        vm.$store.commit('showToast', toast)
    },
    
    async iadeIhracatEArchiveInvoicePrint() {
      if(!this.checkData(this.selectedOrders, ['length'], 1)) return
      if(!this.checkData(this.selectedOrders[0], ['status'], ['shippingTrackingNumber'])) return

      await this.$store.dispatch('order_getInvoicePdf',{"id": this.selectedOrders[0].id, "order": true });
      if(this.$store.getters.orderStatus.success){
        let params = {
          orderId: this.selectedOrders[0].id,
          returnForm: true,
          eArchive: true
        }
        await this.$store.dispatch('order_printEtgbForm', params)
        await this.openModalEvent('print', 'confirm', null, 'İade, İhracat ve E-Arşiv Fatura Bas: ' + this.selectedOrders[0].id+' formu yazırmak ister misin ? ')
        this.$refs.confirm.$refs.desc.contentDocument.write(this.$store.getters.orderJSP) 
      }else{
        if (this.selectedOrders[0].shippingTrackingNumber === null || this.selectedOrders[0].shippingTrackingNumber === '') {
          const toast = {
            msg: 'Bu faturaya ait Ettn numarası yoktur!',
            header: 'Uyarı',
            color: 'warning'
          }
          vm.$store.commit('showToast', toast)
        }
      }
    },
    async detailInvoice() {
      if(!this.checkData(this.selectedOrders, ['length'], 1)) return
     
      const params = {
        "orderId": this.selectedOrders[0].id,
        "emailAddress": this.selectedOrders[0].customerEmailAddress,
        "isProformaInvoice": false
      };
      await this.$store.dispatch('order_printInvoiceDetail', params)
      await this.openModalEvent('print', 'confirm', null, 'Fatura Detay Görüntüle: '+ this.selectedOrders[0].id + ' faturayı yazdırmak ister misin?')
      this.$refs.confirm.$refs.desc.contentDocument.write(this.$store.getters.orderJSP)
    },
    async printReturnForm() {
      if(!this.checkData(this.selectedOrders, ['length'], 1)) return
  
      let params = {
        "orderIds": this.selectedOrders[0].id
      };
      await this.$store.dispatch('order_printReturnForm', params)
      await this.openModalEvent('print', 'confirm', null, 'İade Formu Bas: '+ this.selectedOrders[0].id + ' iade formunu yazdırmak ister misin?')
      this.$refs.confirm.$refs.desc.contentDocument.write(this.$store.getters.orderJSP)
    },
    async getExcelByOrders() {
      await this.$store.dispatch('order_getExcel',{responseType: 'blob'})
      if(this.$store.getters.orderStatus){
        const url = window.URL.createObjectURL(new Blob([this.$store.getters.orderStatus]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Siparişler_' + moment(new Date).format('DD/MM/YYYY HH:mm:ss') + '.xls');
        document.body.appendChild(link);
        link.click();
      }
    },
    async eInvoicePrint(orderId, emailAddress, isPackingSlip, isGiftInvoice) {
      let params = {
        "orderId": orderId,
        "emailAddress": emailAddress,
        "isPackingSlip": true,
        "isGiftInvoice": isGiftInvoice,
      };
      await this.$store.dispatch('order_eInvoicePrint', params)
      await this.openModalEvent('print', 'confirm', 'confirmEInvoicePrint', 'Sevk Irsaliye Bas: ' +orderId+' - Sevk Irsaliyesini yazdırmak ister misin?')
      this.$refs.confirm.$refs.desc.contentDocument.write(this.$store.getters.orderJSP)
    },

    async confirmEInvoicePrint(){
      let params = {
        orderIds: this.selectedOrders[0].id,
        invoiceIds: this.$refs.confirm.$refs.desc.contentWindow.getInvoiceIds()
      }
      await this.$store.dispatch('order_updateOrderAndInvoiceStatus', params )
      if(this.$store.getters.orderStatus.success){
        // DHL veya UPS ise bildir
        if ([4,6].includes(this.selectedOrders[0].shippingCompanyId)) {
          this.informShippingCompany(undefined)
        } 
        this.openModalEvent()
        await this.filterOrders(this.filterParams);
      }
    },
    async orderDetail() {
      if(!this.checkData(this.selectedOrders, ['length'], 1)) return
      await this.$store.dispatch('encryptemail',{ "orderId": this.selectedOrder.id, "email": this.selectedOrder.customerEmailAddress})
      if(this.$store.getters.encrytedEmail.success){
        await this.$store.dispatch('orderDetail',{ "orderId": this.selectedOrder.id, "encryEmail": this.$store.getters.encrytedEmail.encrytedEmail})
        await this.openModalEvent('print', 'confirm', this.selectedOrder, 'Sipariş Detayı: '+this.selectedOrder.id)
        this.noFooter = true;
        this.$store.commit('orderJSP',this.$store.getters.orderDetailCaller)
        this.$refs.confirm.$refs.desc.contentDocument.write(this.$store.getters.orderDetailCaller)
      }
    },
    async checkList() {
      let print = true
      let selected = []
      if(!this.checkData(this.selectedOrders, ['length'], 10)) return
      
      for (var i = 0; i < this.selectedOrders.length; i++) {
        if(!this.checkData(this.selectedOrders[i], ['orderStatus'], ['canceled', 'returned'])) {
          print = false;
          break;
        }else{
          selected.push(this.selectedOrders[i].id + "," + this.selectedOrders[i].customerEmailAddress);
        }
      }
      
      if(print){
        let str = selected.join('|')
        await this.$store.dispatch('order_CheckList', {paramStr: str})
        await this.openModalEvent('print', 'confirm', null, 'Sipariş Check List yazdırmak ister misin?')
        this.$refs.confirm.$refs.desc.contentDocument.write(this.$store.getters.orderJSP)
      }
    },
    async informShippingCompany(force) {

      let orderIds = null;
      let toast;

      if(this.selectedOrders.length > 0) {

        orderIds = this.selectedOrders.map(({ id }) => id)
        orderIds = orderIds.join(",")

      }
      else 
        orderIds = this.ids.trim();
      
      let params = { 
        "orderIds": orderIds,
        "forceInform": force
      }

      await this.$store.dispatch('order_informShippingCompany', params)

      if(this.$store.getters.orderStatus.success){

        toast = {
          msg: this.$store.getters.orderStatus.success + ' ID li sipariş bilgileri başarılı bir şekilde gönderilmiştir.',
          header: 'BAŞARILI',
          color: 'success'
        }

      } else {

        toast = {
          msg: 'HATALI: ' + this.$store.getters.orderStatus.error+' ID li sipariş bilgileri gönderilememiştir!\n HATA MESAJI: ' + this.$store.getters.orderStatus.errMsg,
          header: 'HATALI',
          color: 'danger'
        }

      }

      vm.$store.commit('showToast', toast)
      this.ids = [];
      await this.filterOrders(this.filterParams);
    },
    openChangeOrder: async function(){
      if(!this.checkData(this.selectedOrders, ['length'], 1)) return
      await this.$store.dispatch('encryptemail',{ "orderId": this.selectedOrder.id, "email": this.selectedOrder.customerEmailAddress})
      if(this.$store.getters.encrytedEmail.success){
        let url = process.env.VUE_APP_SERVERINFO_WEBDOMAIN + "/order/change?id=" + this.selectedOrder.id + "&email=" + this.$store.getters.encrytedEmail.encrytedEmail+ "&userType=001";
        window.open(url, '_blank');
      }
    },
   
    async printPackingSlipNew(){
      if(!this.checkData(this.selectedOrders, ['length'], 1)) return
      if(!this.checkData(this.selectedOrders[0], ['status'], ['ettn', 'gibFlag']) ) return
      this.checkData(this.selectedOrders[0], ['status'], ['invoicePrintedFlag'])
      let params = { "orderId": this.selectedOrders[0].id };
      this.$store.dispatch('order_blackListSearch', params)
      this.$store.dispatch('order_paymentLogSearch', params)
      this.openModalEvent('list', 'orderPreparePackingSlipList', this.selectedOrders[0], 'Fatura Yazdır' )
    },

    async prepareIrsaliye() {
      if(!this.checkData(this.selectedOrders, ['length'], 1)) return
      this.checkData(this.selectedOrders[0], ['status'], ['invoicePrintedFlag'])
      
      let params = {
        "orderId": this.selectedOrders[0].id,
        "orderDate": moment(this.selectedOrders[0].createTime).format('DD.MM.YYYY HH:mm:ss'),
      };

      await this.$store.dispatch('order_invoiceTimeControl', params)

      if(this.$store.getters.orderStatus.success){
        this.$store.dispatch('order_blackListSearch', params)
        this.$store.dispatch('order_paymentLogSearch', params)
        this.openModalEvent('list', 'orderPrepareInvoiceList', this.selectedOrders[0], 'Fatura Hazırla' )
      }else{
        let toast = {
          msg: 'Siparişin verilme zamanı yeni, birazdan tekrar deneyiniz.',
          header: 'Uyarı',
          color: 'warning'
        }
        vm.$store.commit('showToast', toast)
      }
    },

    async userNoteSearch() {
      let formData = new FormData();
      formData.append('page', 1)
      formData.append('start', 0)
      formData.append('limit', 25)
      formData.append('sort', 'id')
      formData.append('dir', 'DESC')

      let params = {
        "noteOnModel": 'nc_order',
        "modelId": this.selectedOrders[0].id,
        formData: formData
      }
      await this.$store.dispatch('user_notes', params)
      this.openModalEvent('list', 'orderNoteList', this.selectedOrders[0], 'Not (Sipariş: ' + this.selectedOrders[0].id +')')
    },

    copyStringToClipboard(str) {
      var el = document.createElement('textarea');
      el.value = str;
      el.setAttribute('readonly', '');
      el.style = {position: 'absolute', left: '-9999px'};
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    },
    
    paymentTypeTool(item) {
      var toolHtml = (item.creditCardType !== '' ? item.creditCardType : 'Kayıtlı kart tipi bulunamadı!') +
      (item.secure3DFlag ? ', (3DSecure)' : '') +
      (item.bankName !== '' ? item.bankName : 'Kayıtlı banka adı bulunamadı!' )+
      (item.transactionID !== '' ? item.transactionID : 'Transaction ID\' : \'Kayıtlı transaction bulunamadı!') +
      (item.posName !== '' ? item.posName : 'Pos Name\' : \'Kayıtlı banka bulunamadı!')
      return toolHtml;
    },
  },
  async created() {
    if (this.$route.query.customerId !== null && this.$route.query.customerId !== undefined) {
      this.filterParams = {
        id: this.$route.query.customerId,
        customerEmailAddress: this.$route.query.emailAddress
      }
    } else if (this.$route.query.emailAddress !== null && this.$route.query.emailAddress !== undefined) {
      this.filterParams = {
        customerEmailAddress: this.$route.query.emailAddress
      }
    }
    this.filterOrders(this.filterParams);

    this.$store.dispatch('orderStatusType_list')
    this.$store.dispatch('shippingCompany_list')
    this.$store.dispatch('allCountries_list')
  }
}
</script>
<style scoped>
  .form-group{
    margin-bottom: 0 !important;
    .form-control{
      padding: 0 !important;
    }
  }
</style>